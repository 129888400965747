/* @define reality-check */
.reality-check {
    &__text {
        &:not(:last-child) {
            margin-bottom: 1.2rem;
        }
        &--center {
            text-align: center;

            @include mobile {
                text-align: left;
            }
        }
        &--description {
            @include mobile {
                font-size: 1.2rem;
            }
        }
    }
    &__separator {
        width: 100%;
        height: 1px;
        background-color: var(--general-section-1);
        margin: 2.4rem 0;

        &--large {
            margin: 4rem 0;
        }
        @include mobile {
            margin: 1.6rem 0;
        }
    }
    &__fieldset {
        max-width: 33.5rem;
        margin: auto;
        padding-top: 1.2rem;
    }
    &__column {
        &-wrapper {
            display: flex;
            flex-direction: row;
            overflow-y: auto;

            @include mobile {
                flex-direction: column;
                padding: 0.8rem 2.4rem;

                @media only screen and (max-width: 340px) {
                    padding: 0.8rem 1.6rem;
                }
            }
        }
        &:not(:last-child) {
            margin-right: 2.4rem;

            @include mobile {
                margin-right: 0;
                margin-bottom: 0.8rem;
            }
        }
        @include mobile {
            &-content {
                padding-top: 1.6rem;
            }
        }
    }
    &__row {
        width: 25.1rem;
        height: 4rem;
        background-color: var(--general-section-1);
        margin-bottom: 0.1rem;
        display: flex;
        align-items: center;
        padding: 1rem 1.6rem;
        font-size: 1.4rem;

        &:first-child {
            border-radius: 8px 8px 0 0;
        }
        &:last-child {
            margin-bottom: 0.8rem;
            border-radius: 0 0 8px 8px;
        }
        :first-child {
            width: 12.8rem;
            padding-right: 0.8rem;

            @include mobile {
                width: 16.8rem;
            }
        }
        :nth-child(2) {
            @include mobile {
                margin-left: auto;
            }
        }
    }
    &__button {
        &--full-width {
            width: 100%;

            @include mobile {
                width: 25.1rem;
                display: flex;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &__submit {
        background-color: unset;
        padding: 0 0.8rem;

        @include mobile {
            padding: 0 0.8rem;
        }
    }
    @include mobile {
        &__wrapper {
            overflow-y: auto;
            overflow-x: hidden;
            padding: 2.4rem;
        }
    }
}

.dc-modal__container_reality-check {
    & .dc-modal {
        &-header {
            &__title--reality-check {
                @include mobile {
                    margin: auto;
                    text-align: center;
                    line-height: 1.43;
                }
            }
        }
        &-body {
            @include mobile {
                padding: 0;
            }
        }
    }
}
