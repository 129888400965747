/** @define error-box */
.error-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    text-align: center;
    color: var(--text-colored-background);
}
