/** @define settings-language */
.settings-language {
    @include mobile {
        display: flex;
        padding: 1.5rem 1.5rem 8rem;
    }

    &__language-container {
        display: grid;
        grid-template-columns: repeat(4, minmax(90px, 1fr));
        grid-gap: 1.6rem;

        @include mobile {
            grid-template-columns: repeat(2, minmax(50%, 1fr));
            grid-gap: 0.3rem;
            grid-template-rows: auto;

            @include mobile {
                &--disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }
    &__language {
        &-button_wrapper {
            display: flex;
            align-items: center;
        }

        &-link {
            color: var(--text-prominent);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            border-radius: $BORDER_RADIUS;
            padding: 16px;
            @include mobile {
                padding: 8px;

                &--pre-appstore {
                    padding: 1.6rem;
                }
            }
            text-decoration: none;
            background-color: var(--state-normal);
            cursor: pointer;

            &--active {
                border: 1px solid var(--border-active);
                color: var(--text-prominent);
            }
        }
        &-flag {
            width: 5rem;
            height: 4rem;
            cursor: pointer;

            @include mobile {
                margin-top: 1rem;
                width: 4rem;
                height: 5rem;
            }
        }
        &-name {
            @include typeface(--paragraph-left-normal-black);
            color: var(--text-prominent);
            margin-top: 0.8rem;

            &--active {
                font-weight: bold;
                color: var(--text-prominent);
            }

            @include mobile {
                font-size: 1.4rem;
            }
        }
        &--radio-button {
            display: none;
        }
        &--center-label {
            text-align: center;
        }
    }
}
.account-form {
    &--language-settings {
        overflow: auto !important;
    }
}
