/** @define login-prompt */
.login-prompt {
    height: calc(100vh - 84px);
    margin: 0 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__icon {
        height: 6.4em;
        width: 6.4em;
        margin-bottom: 1em;

        &-svg {
            height: 100%;
            width: 100%;
        }
    }
    &__message {
        font-size: 1.4em;
    }
}
