/** @define cookie-banner; weak */
.cookie-banner {
    position: fixed;
    background-color: var(--general-main-2);
    box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.08), 0 0 24px 0 rgba(0, 0, 0, 0.08);
    @include typeface(--paragraph-center-normal-black);
    color: var(--text-general);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    padding: 2.8rem 0;
    bottom: 0;
    width: 100%;
    height: fit-content;
    text-align: center;
    transition: top 0.3s ease-in-out;
    z-index: 1000;

    @include mobile {
        font-size: 12px;
        font-weight: bold;
        height: fit-content;
        padding: 2.4rem 0;
    }

    &--theme-dark {
        box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.64), 0 0 24px 0 rgba(0, 0, 0, 0.64);
    }
    &--is-closed {
        transform: translateY(152px);
        @include mobile {
            transform: translateY(168px);
        }
    }
    .description {
        margin: 0 auto;
        @include desktop {
            width: 640px;
        }
        @include mobile {
            max-width: 296px;
        }
    }
    &__btn-dont-accept,
    &__btn-dont-accept:hover,
    &__btn-accept,
    &__btn-accept:hover {
        margin: 20px auto 0;
        height: 40px;
        border-width: 2px;
        border-radius: $BORDER_RADIUS;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;

        @include mobile {
            height: 32px;
            border-width: 1px;
        }
    }
    &__btn-dont-accept,
    &__btn-dont-accept:hover {
        width: fit-content;
        margin-right: 8px;
    }
    &__btn-accept {
        width: fit-content;
    }
}
