.dc-modal__container_welcome {
    overflow: visible;

    @include mobile {
        overflow: auto;
        max-height: 95vh;
    }
}

.welcome-cr {
    background-color: var(--general-section-4);
}

.welcome {
    // styles for cr

    &__header {
        margin: 2.4rem;
    }

    &__main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mobile {
            flex-wrap: wrap;
        }
    }

    &-item {
        width: 100%;
        max-width: 58.8rem;
        height: 10.4rem;
        display: flex;
        flex-direction: row;
        border-radius: 0.8rem;
        margin-bottom: 1.6rem;
        position: relative;
        background-color: var(--general-main-1);
        cursor: pointer;

        @media (min-width: 992px) {
            border: 2px solid transparent;
            height: 11.2rem;
        }

        &:hover {
            border-color: var(--border-active);
        }

        &:active {
            border-color: var(--border-active);
        }

        &__icon {
            width: 7.8rem;

            @media (min-width: 992px) {
                width: 8.3rem;
                position: relative;
                left: -2px;
                top: -2px;
            }

            .mobile-icon {
                border-radius: 8px;
            }
        }

        &__arrow {
            justify-content: center;
            align-items: center;
            flex-basis: 32px;
            display: flex;
        }

        &__body {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            padding-left: 8px;
            justify-content: space-between;

            @media (min-width: 991px) {
                padding-left: 16px;
            }
        }

        &__main {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__options {
            margin: 0.4rem 0;
        }

        &__option {
            padding: 0 0.2rem;
            border-right: 1px solid var(--general-section-1);
            line-break: anywhere;

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                border: none;
            }

            @media (min-width: 992px) {
                padding: 0 0.8rem;
            }
        }
    }

    &-item-small {
        height: 8.6rem;

        @media (min-width: 992px) {
            height: 11.2rem;
        }

        &__icon {
            width: 6rem;

            @media (min-width: 992px) {
                width: 8.3rem;
            }
        }
    }

    // styles for non cr
    &__title {
        margin: 2.4rem;
    }
    &__message {
        display: none;
        flex-direction: row;
        position: fixed;
        align-items: center;

        &__text {
            @include typeface(--title-left-bold-active);
            padding-left: 0.8rem;
        }
        &--left {
            top: 50%;
            left: -1.6rem;
            transform: translate(-100%, -50%);

            .welcome__message__text {
                width: 12rem;
            }
        }
        &--right {
            top: 50%;
            right: -1.6rem;
            transform: translate(100%, -50%);
        }
        &--visible {
            @include desktop {
                display: flex;
            }
        }
    }

    &__carousel {
        width: 100%;
    }

    &-column {
        width: 35rem;
        height: 51.3rem;
        position: relative;
        padding: 1.6rem 3.2rem;
        background-repeat: no-repeat;
        background-position: top right;

        @include mobile {
            width: 100%;
            height: 55rem;
            box-shadow: none;

            &:not(:last-child) {
                margin-bottom: 3rem;
            }
        }

        @include desktop {
            &:hover {
                box-shadow: 0 0 3rem 0.5rem var(--shadow-drop);
            }
        }

        &--left {
            @include desktop {
                padding-right: 2rem;
            }

            &:hover {
                background-image: url('~Images/common/welcome-bg-blue.svg');
            }

            @include mobile {
                background-image: url('~Images/common/welcome-bg-blue.svg');
            }
        }

        &--right {
            @include desktop {
                padding-left: 2rem;
            }

            &:hover {
                background-image: url('~Images/common/welcome-bg-red.svg');
            }

            @include mobile {
                background-image: url('~Images/common/welcome-bg-red.svg');
            }
        }

        &__icons {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        &__icon {
            margin: 0.8rem 0.6rem 1.6rem;
        }

        &__title {
            @include typeface(--title-center-bold-prominent);
        }

        &__description {
            @include typeface(--small-center-normal-prominent);
            line-height: 1.5;
            height: 5.8rem;
            padding: 0.8rem 0 1.6rem;
            border-bottom: 1px solid var(--general-section-1);

            @include mobile {
                height: 6.8rem;
            }
        }

        &__footer {
            position: absolute;
            bottom: 1.6rem;
            left: 1.6rem;
            width: calc(100% - 3.2rem);
            @include mobile {
                left: 3.2rem;
                width: calc(100% - 6.4rem);
                bottom: -0.4rem;
            }

            &__text {
                @include typeface(--small-center-normal-grey);
                padding-top: 0.8rem;
                @include desktop {
                    display: none;
                }
            }
        }

        &__button {
            width: 100%;
        }

        &__platforms {
            &__title {
                padding: 1.6rem;
            }

            &__container {
                display: flex;
                flex-direction: column;
            }
        }

        &__platform {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto auto;
            grid-column-gap: 0.8rem;
            grid-row-gap: 0.4rem;
            margin-bottom: 1.6rem;

            @include mobile {
                margin-bottom: 0.6rem;
            }

            &__icon {
                grid-row: 1 / -1;
                grid-column: 1;
                margin-right: 1.2rem;
            }

            &__title {
                @include typeface(--small-left-bold-prominent);
                grid-row: 1;
                grid-column: 2;
            }

            &__description {
                @include typeface(--small-left-normal-grey);
                grid-row: 2;
                grid-column: 2;
                min-height: 3.6rem;

                @include mobile {
                    min-height: 4.8rem;
                }
            }

            &-footer {
                &--has-separator {
                    border-bottom: 1px solid var(--border-disabled);
                    padding-bottom: 1.6rem;
                }
                .dc-btn {
                    width: 100%;
                }
            }

            &:not(:first-child) {
                margin-top: 2.4rem;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        @include mobile {
            flex-wrap: wrap;
        }
    }

    &__footer {
        @include typeface(--small-center-normal-grey);
        padding: 2.4rem;

        @include mobile {
            display: none;
        }
    }
}
