.trading-hub-header {
    position: fixed;
    z-index: 99;
    width: 100%;
    height: $HEADER_HEIGHT;
    left: 0;
    top: 0;
    box-sizing: border-box;
    border-bottom: 1px solid var(--general-section-1);
    background-color: var(--general-main-1);
    backface-visibility: hidden;
    transition: opacity 0.3s;
    display: flex;

    &--is-disabled:after {
        opacity: 1;
        pointer-events: auto;
    }

    &--is-hidden {
        display: none !important;
    }

    @media screen and (max-width: 380px) {
        &__cashier-button {
            display: none;
        }
    }

    @include mobile {
        display: flex;
        justify-content: space-between;
        height: 4rem;
    }

    &__logo-wrapper {
        display: block;
        cursor: pointer;
        @media screen and (max-width: 380px) {
            &--cashier {
                display: none;
            }
        }
    }

    &__menu-left {
        display: inline-flex;
        height: #{$HEADER_HEIGHT - 1px};
        align-items: center;

        @include mobile {
            height: 3.8rem;
        }

        &-extensions {
            display: flex;
            overflow: hidden;
        }
    }

    &__mobile-parent {
        display: flex;
        align-items: center;
        padding: 1rem;
    }

    &__menu-right {
        justify-content: flex-end;
        display: inline-flex;
        flex: 1;
        align-items: center;

        &--items {
            display: flex;
            align-items: center;

            &--onboarding {
                padding: 2.5rem;
                cursor: pointer;

                @include mobile {
                    padding: 1rem;
                }
            }

            &--notifications {
                @include mobile {
                    padding: 0 2rem 0 1rem;
                }
                &__cashier {
                    @include mobile {
                        padding-right: 1rem;
                    }
                }
            }

            &--account-toggle {
                height: #{$HEADER_HEIGHT - 1px};
                @include mobile {
                    height: 3.8rem;
                }
                .acc-info__wrapper {
                    @include mobile {
                        margin-right: 0;
                    }
                    .acc-info__separator {
                        width: unset;
                    }
                }
            }
        }
    }

    &__menu-middle {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        justify-content: space-between;
    }

    &__logo {
        height: 1.8rem;
        padding: 0 1rem 0 1.5rem;
        margin-bottom: 0.2rem !important;

        @include mobile {
            margin-left: 0.7rem;
        }
    }

    &__tradershub {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 0 1rem 0 1.6rem;
        cursor: pointer;

        &:hover:not(&--active) {
            background-color: var(--general-hover);
        }

        &--active > &--text {
            font-weight: bold;
            color: var(--text-prominent);
        }

        &--home-logo {
            padding-right: 0.8rem;
        }

        &--text {
            font-size: 1.6rem;
        }

        > * {
            cursor: pointer;
        }

        &--onboarding {
            padding: 2rem;
            margin-right: 0.3rem;
            cursor: pointer;
        }
    }

    &__divider {
        width: 0.1rem;
        height: 3rem;
        margin-left: 0.8rem;
        background: var(--general-section-2);

        @include mobile {
            height: 2.4rem;
        }
    }

    &__redirect {
        display: flex;
        cursor: pointer;

        &--link {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            padding: 0.5rem;
            text-decoration: none;
        }

        &--cashierlink {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            text-decoration: none;
            padding: 2rem;
        }

        &--cashiertext {
            padding: 0.7rem;
        }

        &--beta {
            padding: 0 0.7rem;
        }
    }

    &__dtrader--redirect {
        display: flex;
        margin-right: 1rem;
        align-items: center;

        &--link {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            text-decoration: none;
        }

        &--beta {
            padding: 0 0.5rem;
        }
    }

    &__setting {
        margin-right: 2.2rem;

        // @include mobile {
        //     margin-left: 2.2rem;
        // }

        &--icon {
            cursor: pointer;
        }
    }

    &__notification {
        @include mobile {
            .notifications-toggle {
                margin-right: 0;
            }
        }
    }
}
