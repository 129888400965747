:host,
:root {
    // Text sizes
    --text-size-xxxxs: 0.8rem;
    --text-size-xxxs: 1rem;
    --text-size-xxs: 1.2rem;
    --text-size-xs: 1.4rem;
    --text-size-s: 1.6rem;
    --text-size-xsm: 1.8rem;
    --text-size-sm: 2rem;
    --text-size-m: 2.4rem;
    --text-size-l: 3.2rem;
    --text-size-xl: 4.8rem;
    --text-size-xxl: 6.4rem;

    //Line Height
    --text-lh-xxs: 1;
    --text-lh-xs: 1.25;
    --text-lh-s: 1.4;
    --text-lh-m: 1.5;
    --text-lh-l: 1.75;
    --text-lh-xl: 2;
    --text-lh-xxl: 2.4;

    //Font Weight
    --text-weight-lighter: lighter;
    --text-weight-normal: normal;
    --text-weight-bold: bold;
    --text-weight-bolder: bolder;

    //Text Align
    --text-align-left: start;
    --text-align-right: right;
    --text-align-center: center;

    // Brand primary colors
    --brand-white: #{$color-white};
    --brand-dark-grey: #{$color-black};
    --brand-red-coral: #{$color-red};
    --brand-orange: #{$color-orange};
    // Brand secondary colors
    --brand-secondary: #{$color-green};

    // Wallet gradient background
    --wallet-airtm: #{$gradient-airtm};
    --wallet-banxa: #{$gradient-banxa};
    --wallet-bitcoin: #{$gradient-bitcoin};
    --wallet-credit: #{$gradient-credit};
    --wallet-dp2p: #{$gradient-dp2p};
    --wallet-fasapay: #{$gradient-fasapay};
    --wallet-jeton: #{$gradient-jeton};
    --wallet-mastercard: #{$gradient-mastercard};
    --wallet-neteller: #{$gradient-neteller};
    --wallet-paylivre: #{$gradient-paylivre};
    --wallet-paysafe: #{$gradient-paysafe};
    --wallet-perfectmoney: #{gradient-perfectmoney};
    --wallet-skrill: #{$gradient-skrill};
    --wallet-sticpay: #{$gradient-sticpay};
    --wallet-virtual: #{$gradient-virtual};
    --wallet-visa: #{$gradient-visa};
    --wallet-webmoney: #{$gradient-webmoney};

    // App Cards gradient background
    --app-card-virtual: #{$gradient-virtual};
    --app-card-virtual-swap-free: #{$gradient-virtual-swap-free};

    .theme--light {
        // General
        --general-main-1: #{$color-white};
        --general-main-2: #{$color-white};
        --general-main-3: #{$color-grey-1};
        --general-main-4: #{$alpha-color-white-4};
        --general-section-1: #{$color-grey-2};
        --general-section-2: #{$color-grey-2};
        --general-section-3: #{$color-grey-11};
        --general-section-4: #{$color-grey-12};
        --general-section-5: #{$color-grey-2};
        --general-section-6: #{$color-grey-2};
        --general-disabled: #{$color-grey-3};
        --general-hover: #{$color-grey-4};
        --general-active: #{$color-grey-5};
        // Icons and Texts
        --text-general: #{$color-black-1};
        --text-primary: #{$color-grey-1};
        --text-less-prominent: #{$color-grey-1};
        --text-prominent: #{$color-black-1};
        --text-disabled: #{$color-grey-1};
        --text-loss-danger: #{$color-red-1};
        --text-profit-success: #{$color-green-1};
        --text-warning: #{$color-yellow};
        --text-red: #{$color-red};
        --text-blue: #{$color-blue-3};
        --text-info-blue: #{$color-blue};
        --text-info-blue-background: #{$color-blue-5};
        --text-colored-background: #{$color-white};
        --icon-light-background: #{$color-black-9};
        --icon-dark-background: #{$color-white};
        --icon-grey-background: #{$color-grey-2};
        --icon-black-plus: #{$color-black-7};
        --text-status-info-blue: #{$color-blue};
        --text-hint: #{$color-black-1};
        // Purchase
        --purchase-main-1: #{$color-green-1};
        --purchase-section-1: #{$color-green-2};
        --purchase-main-2: #{$color-red-1};
        --purchase-section-2: #{$color-red-4};
        --purchase-disabled-main: #{$color-grey-3};
        --purchase-disabled-section: #{$color-grey-4};
        // Buttons
        --button-primary-default: var(--brand-red-coral);
        --button-secondary-default: #{$color-grey-1};
        --button-tertiary-default: transparent;
        --button-primary-light-default: #{$alpha-color-red-2};
        --button-primary-hover: #{$color-red-5};
        --button-secondary-hover: #{$alpha-color-black-3};
        --button-tertiary-hover: #{$alpha-color-red-1};
        --button-primary-light-hover: #{$alpha-color-red-3};
        --button-toggle-primary: #{$color-blue-3};
        --button-toggle-secondary: #{$color-grey-5};
        --button-toggle-alternate: #{$color-white};
        // Overlay
        --overlay-outside-dialog: #{$alpha-color-black-1};
        --overlay-inside-dialog: #{$alpha-color-white-1};
        // Shadow
        --shadow-menu: #{$alpha-color-black-4};
        --shadow-menu-2: #{$alpha-color-black-4};
        --shadow-drop: #{$alpha-color-black-3};
        --shadow-box: #{$COLOR_LIGHT_BLACK_2};
        // States
        --state-normal: #{$color-white};
        --state-hover: #{$color-grey-4};
        --state-active: #{$color-grey-5};
        --state-disabled: #{$color-grey-3};
        --checkbox-disabled-grey: #{$color-grey-6};
        --sidebar-tab: #{$color-grey-6};
        // Border
        --border-normal: #{$color-grey-5};
        --border-normal-1: #{$color-grey-5};
        --border-normal-2: #{$color-grey-5};
        --border-normal-3: #{$color-grey-6};
        --border-hover: #{$color-grey-1};
        --border-hover-1: #{$color-black-9};
        --border-active: var(--brand-secondary);
        --border-disabled: #{$color-grey-3};
        --border-divider: #{$color-grey-2};
        // Fill
        --fill-normal: #{$color-white};
        --fill-normal-1: #{$color-grey};
        --fill-hover: #{$color-grey-1};
        --fill-active: var(--brand-secondary);
        --fill-disabled: #{$color-grey-3};
        // Status
        --status-default: #{$color-grey-3};
        --status-adjustment: #{$color-grey-1};
        --status-danger: #{$color-red-1};
        --status-success: #{$color-green-1};
        --status-warning: #{$color-yellow};
        --status-warning-transparent: #{$alpha-color-yellow-1};
        --status-transfer: #{$color-orange};
        --status-info: #{$color-blue};
        --status-colored-background: #{$color-white};
        --status-alert-background: #{$color-yellow-3};
        // Dashboard
        --dashboard-swap-free: #{$gradient-color-black-3};
        --dashboard-app: #{$gradient-color-black-4};
        // Payment methods
        --payment-skrill: #{$gradient-color-purple};
        --payment-visa: #{$gradient-color-blue};
        --payment-mastercard: #{$gradient-color-orange};
        --payment-credit-and-debit: #{$gradient-color-black-2};
        --payment-bitcoin: #{$gradient-color-orange-2};
        --payment-usd-coin: #{$gradient-color-blue-2};
        --payment-neteller: #{$gradient-color-green-3};
        --payment-webmoney: #{$gradient-color-blue-3};
        --payment-jeton: #{$gradient-color-orange-3};
        --payment-sticpay: #{$gradient-color-orange-4};
        --payment-airtm: #{$gradient-color-blue-4};
        --payment-dp2p: #{$gradient-color-red-3};
        --payment-deriv: #{$gradient-color-red-4};
        // Transparentize
        --transparent-success: #{$alpha-color-green-1};
        --transparent-info: #{$alpha-color-blue-1};
        --transparent-hint: #{$alpha-color-blue-3};
        --transparent-danger: #{$alpha-color-red-2};
        --transparent-correct-message: #{$color-green-5};
        --transparent-fake-message: #{$color-red-6};
        /* TODO: change to styleguide later */
        // Gradient
        --gradient-success: #{$gradient-color-green-1};
        --gradient-danger: #{$gradient-color-red-1};
        --contract-gradient-danger: #{$contract-gradient-color-red-1};
        --gradient-right-edge: #{$gradient-color-white};
        --gradient-blue: #{$gradient-color-blue-5};
        --gradient-gold: #{$gradient-color-gold};
        --gradient-green: #{$gradient-color-green-4};
        // Badge
        --badge-white: #{$color-white};
        --badge-blue: #{$color-blue-4};
        --badge-violet: #{$color-blue-2};
        --badge-green: #{$color-green-3};
    }
    .theme--dark {
        // General
        --general-main-1: #{$color-black};
        --general-main-2: #{$color-black-3};
        --general-main-3: #{$color-black-4};
        --general-main-4: #{$alpha-color-black-7};
        --general-section-1: #{$color-black-3};
        --general-section-2: #{$color-black};
        --general-section-3: #{$color-black-5};
        // @TODO: get color from design
        --general-section-4: #{$color-black-5};
        --general-section-5: #{$color-black-5};
        --general-section-6: #{$color-grey-7};
        --general-disabled: #{$color-black-4};
        --general-hover: #{$color-black-5};
        --general-active: #{$color-black-8};
        // Icons and Texts
        --text-prominent: #{$color-white};
        --text-general: #{$color-grey};
        --text-less-prominent: #{$color-grey-7};
        --text-primary: #{$color-grey-1};
        --text-disabled: #{$color-black-6};
        --text-profit-success: #{$color-green-3};
        --text-loss-danger: #{$color-red-2};
        --text-red: #{$color-red};
        --text-colored-background: #{$color-white};
        --text-info-blue: #{$color-blue-6};
        --text-info-blue-background: #{$color-blue-7};
        --text-status-info-blue: #{$color-blue};
        --text-hint: #{$color-grey};
        --icon-light-background: #{$color-black-9};
        --icon-dark-background: #{$color-white};
        --icon-grey-background: #{$color-black-1};
        --icon-black-plus: #{$color-white};
        // Purchase
        --purchase-main-1: #{$color-green-3};
        --purchase-section-1: #{$color-green-4};
        --purchase-main-2: #{$color-red-2};
        --purchase-section-2: #{$color-red-3};
        --purchase-disabled-main: #{$color-black-4};
        --purchase-disabled-section: #{$color-black};
        --button-primary-default: var(--brand-red-coral);
        --button-secondary-default: #{$color-grey-7};
        --button-tertiary-default: transparent;
        --button-primary-light-default: #{$alpha-color-red-2};
        --button-primary-hover: #{$color-red-5};
        --button-secondary-hover: #{$alpha-color-white-3};
        --button-tertiary-hover: #{$alpha-color-red-1};
        --button-primary-light-hover: #{$alpha-color-red-3};
        --button-toggle-primary: #{$color-blue-3};
        --button-toggle-secondary: #{$color-black-8};
        --button-toggle-alternate: #{$color-black-8};
        // Overlay
        --overlay-outside-dialog: #{$alpha-color-black-1};
        --overlay-inside-dialog: #{$alpha-color-black-2};
        // Shadow
        --shadow-menu: #{$alpha-color-black-5};
        --shadow-menu-2: #{$alpha-color-black-1};
        --shadow-drop: #{$alpha-color-black-6};
        --shadow-box: #{$COLOR_DARK_GRAY_3};
        // States
        --state-normal: #{$color-black};
        --state-hover: #{$color-black-5};
        --state-active: #{$color-black-8};
        --state-disabled: #{$color-black-4};
        --checkbox-disabled-grey: #{$color-grey-6};
        --sidebar-tab: #{$color-grey-7};
        // Border
        --border-normal: #{$color-black-8};
        --border-normal-1: #{$color-grey-5};
        --border-normal-2: #{$color-grey-1};
        --border-normal-3: #{$color-grey-7};
        --border-hover: #{$color-grey-7};
        --border-hover-1: #{$color-black-9};
        --border-active: var(--brand-secondary);
        --border-disabled: #{$color-black-4};
        --border-divider: #{$color-grey-13};
        // Fill
        --fill-normal: #{$color-black};
        --fill-normal-1: #{$color-black-1};
        --fill-hover: #{$color-grey-7};
        --fill-active: var(--brand-secondary);
        --fill-disabled: #{$color-black-4};
        // Status
        --status-default: #{$color-grey-3};
        --status-adjustment: #{$color-grey-1};
        --status-danger: #{$color-red-2};
        --status-warning: #{$color-yellow};
        --status-warning-transparent: #{$alpha-color-yellow-1};
        --status-success: #{$color-green-3};
        --status-transfer: #{$color-orange};
        --status-info: #{$color-blue};
        --status-colored-background: #{$color-white};
        --status-alert-background: #{$color-yellow-3};
        // Transparentize
        --transparent-success: #{$alpha-color-green-2};
        --transparent-info: #{$alpha-color-blue-1};
        --transparent-hint: #{$alpha-color-blue-1};
        --transparent-danger: #{$alpha-color-red-2};
        --transparent-correct-message: #{$color-green-5};
        --transparent-fake-message: #{$color-red-6};
        /* TODO: change to styleguide later */
        // Gradient
        --gradient-success: #{$gradient-color-green-2};
        --gradient-danger: #{$gradient-color-red-2};
        --contract-gradient-danger: #{$contract-gradient-color-red-2};
        --gradient-right-edge: #{$gradient-color-black};
        --gradient-blue: #{$gradient-color-blue-5};
        --gradient-gold: #{$gradient-color-gold};
        --gradient-green: #{$gradient-color-green-4};
        // Badge
        --badge-white: #{$color-white};
        --badge-blue: #{$color-blue-4};
        --badge-violet: #{$color-blue-2};
        --badge-green: #{$color-green-3};
    }
}
