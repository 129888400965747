.modal-root {
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9998;
    display: none;
    overflow: hidden;
    opacity: 0;
    background-color: var(--overlay-outside-dialog);

    &:not(:empty) {
        display: flex;
        opacity: 1;
    }
    &--absolute {
        z-index: 9999;
    }
}
