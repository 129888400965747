/** @define header; weak */
.header {
    position: fixed;
    z-index: 99;
    box-sizing: border-box;
    height: $HEADER_HEIGHT;
    width: 100%;
    top: 0;
    transition: opacity 0.3s;
    backface-visibility: hidden;
    background-color: var(--general-main-1);
    border-bottom: 1px solid var(--general-section-1);

    &--is-disabled:after {
        opacity: 1;
        pointer-events: auto;
    }
    &--is-hidden {
        display: none;
    }
    &__menu-mobile-whatsapp {
        &-link {
            text-decoration: none;
            color: var(--text-general);
            padding-left: 1.6rem;
        }
    }

    &__menu-mobile-traders-hub {
        padding-top: 2rem;
    }

    &__menu-mobile-livechat {
        border-bottom: 1px solid var(--general-section-2);
    }

    &__menu-mobile-body-wrapper {
        position: relative;
        height: calc(100% - 4rem);
    }
    &__menu-mobile-platform-switcher {
        position: absolute;
        height: 100%;
        top: 2.4rem;
        left: 0;
    }
    &__menu-mobile-theme {
        border-bottom: 1px solid var(--general-section-2);
        padding-right: 0;

        &--trader-hub {
            border-bottom: 1px solid var(--general-section-2);
        }
    }

    &__menu--separator {
        border-right: 1px solid var(--general-section-1);
        width: 2rem;
        height: 3.2rem;
        margin-right: 1rem;
    }
    &__menu--dtrader--separator {
        border-right: 1px solid var(--general-section-1);
        margin-top: 0.8rem;
        height: 3.2rem;

        &--account {
            margin-right: 1rem;
        }
    }

    &__menu__redirect {
        display: flex;

        &--link {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            text-decoration: none;
        }
    }

    &__redirect--dtratder {
        display: flex;
        margin-left: 50rem;

        &--link {
            display: flex;
            align-items: center;
            margin-right: 5rem;
            padding: 0.5rem;
            text-decoration: none;
        }
    }

    &__menu-mobile-link {
        text-decoration: none;
        display: flex;
        flex: 1;
        align-items: center;

        &--active {
            .header__menu-mobile-link-text {
                font-weight: bold;
                color: var(--text-prominent);
            }
        }
        &-text {
            font-size: 1.4rem;
            color: var(--text-general);
            flex: 1;
        }
        &-icon {
            margin-right: 16px;
            width: 16px;
            height: 16px;
        }
        &-flag-icon {
            margin-right: 16px;
        }
        &-suffix-icon {
            width: 16px;
            height: 16px;
            margin-right: auto;
            margin-left: auto;
        }
    }
    &__menu-items {
        display: flex;
        position: relative;
        box-sizing: border-box;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    &__navbar-icons {
        cursor: pointer;
        padding: 0.8em 1.6em;
        align-self: center;
        height: 100%;

        &--notify-toggle {
            order: 2;
            order: 2;
            padding: 0.5em 1em;
            width: 2em;
        }
    }

    &__menu-divider {
        margin-left: 20rem;
    }

    &__menu-left {
        justify-content: flex-start;
        order: -1;
        display: inline-flex;
        flex: 1;
        height: #{$HEADER_HEIGHT - 1px};

        &-extensions {
            display: flex;
            align-items: center;
            overflow: hidden;
        }
    }
    &__menu-right {
        justify-content: flex-end;
        order: 1;
        display: inline-flex;
        height: #{$HEADER_HEIGHT - 1px};
        position: relative;

        &--hidden {
            display: none;
        }
        @include mobile {
            .acc-info__separator {
                display: none;
            }
            .notifications-toggle {
                margin-right: 0;
            }
        }
    }

    &__menu--back-to-old-ui--dtrader {
        padding: 0 1.5rem 2.5rem;
        width: 110%;
    }

    &__menu--back-to-ui {
        display: flex;
        width: 90%;
        justify-content: space-between;
        border-radius: 1rem;

        &--dark {
            background-color: var(--general-main-1);
            display: flex;
            width: 90%;
            justify-content: space-between;
            border-radius: 1rem;
        }
    }

    &__menu--trading-hub {
        padding: 1.5rem;
    }

    &__menu--trading-hub-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__menu--trading-hub-text {
        width: 13rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__menu--explore-trading-hub {
        border-radius: 1rem;

        &--dark {
            background-color: var(--general-main-1);
        }
        .dc-btn__text {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    &__menu--exit-trading-hub {
        border-radius: 1rem;

        &--dark {
            background-color: var(--general-main-1);
        }
        .dc-btn__text {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    &__menu--trading-hub-beta-icon {
        margin: 0 4rem 0 1rem;
    }

    &__menu--exit-trading-hub-beta-icon {
        margin: 0 4rem 0 1rem;
    }

    &__menu--back-to-ui-text {
        align-items: flex-start;

        &--dark {
            color: var(--text-prominent);
            align-items: flex-start;
        }
    }

    &__menu-mobile-right-arrow {
        align-items: flex-end;
        display: inline-flex;
    }

    &__menu-toggle {
        align-self: center;
        display: flex;
    }
    &__menu-links {
        height: 100%;
        align-self: center;

        @media (max-width: 520px) {
            display: none;
        }
    }
    &__menu-link {
        height: 100%;
        padding: 0 1.6em;
        text-decoration: none;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        transition: background-color 0.3s;
        cursor: pointer;

        &-text {
            @include typeface(--title-left-normal-black);
            padding: 0.5em 0;
            position: relative;
            color: var(--text-general);
            @include colorIcon(var(--text-general), none, var(--text-general));

            &:before {
                position: absolute;
                top: 1.4em;
                left: 1.2em;
            }
            &:after {
                display: block;
                content: attr(title);
                font-weight: 400;
                height: 0;
                overflow: hidden;
                visibility: hidden;
            }
        }
        &:hover:not(&--active) {
            background-color: var(--general-hover);
        }
        &--active {
            & > .header__menu-link-text[title] {
                font-weight: bold;
                color: var(--text-prominent);

                &:after {
                    color: var(--text-prominent);
                }
            }
            .header__icon {
                @extend %inline-icon.active;
            }
        }
    }
    &__logo {
        background-color: var(--brand-secondary);
        border-radius: 10px;
        color: var(--text-colored-background);
        display: inline-block;
        font-size: 1.4rem;
        font-weight: normal;
        height: 20px;
        line-height: 1.43;
        text-align: center;
        width: 50px;
        margin-left: 8px;
    }
    &__mobile-drawer {
        &-icon {
            width: 16px;
            height: 16px;
            transform: scale(1.3); // TODO: fix the icons sizes and remove this
        }
        &-toggle {
            padding: 0 1.2rem;
            border-right: 1px solid var(--general-section-1);
            display: flex;
            align-items: center;
            height: inherit;
        }
    }
    &__title {
        display: inline-block;
        font-weight: bold;
        color: var(--text-prominent);
    }
    &__icon {
        margin-right: 0.8rem;
        vertical-align: middle;
    }
    @include mobile {
        height: $MOBILE_HEADER_HEIGHT;

        .header__menu-left,
        .header__menu-right {
            height: #{$MOBILE_HEADER_HEIGHT - 1px};
        }
    }
}
