.account-settings-toggle {
    svg {
        height: 20px;
        width: 20px;
        @extend %inline-icon;
    }
    &:hover {
        svg {
            @extend %inline-icon.active;
        }
    }
}
