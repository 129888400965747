/*------------------------------------*
 *  # Constants
 *------------------------------------*/

/* stylelint-disable color-no-hex */

/* COLOR PALETTE */

/* colors */
$color-black: #0e0e0e;
$color-black-1: #333333;
$color-black-3: #151717;
$color-black-4: #1d1f20;
$color-black-5: #242828;
$color-black-6: #3e3e3e;
$color-black-7: #000000;
$color-black-8: #323738;
$color-black-9: #5c5c5c;
$color-blue: #377cfc;
$color-blue-1: #0dc2e7;
$color-blue-2: #2a3052;
$color-blue-3: #0796e0;
$color-blue-4: #0677af;
$color-blue-5: #dfeaff;
$color-blue-6: #92b8ff;
$color-blue-7: #182130;
$color-brown: #664407;
$color-green: #85acb0;
$color-green-1: #4bb4b3;
$color-green-2: #3d9494;
$color-green-3: #00a79e;
$color-green-4: #008079;
$color-green-5: #4bb4b329;
$color-grey: #c2c2c2;
$color-grey-1: #999999;
$color-grey-2: #f2f3f4;
$color-grey-3: #eaeced;
$color-grey-4: #e6e9e9;
$color-grey-5: #d6dadb;
$color-grey-6: #d6d6d6;
$color-grey-7: #6e6e6e;
$color-grey-8: #d7d7d7;
$color_grey-9: #868686;
$color-grey-10: #919191;
$color-grey-11: #fafafa;
$color-grey-12: #f5f7fa;
$color-grey-13: #2e2e2e;
$color-orange: #ff6444;
$color-purple: #722fe4;
$color-red: #ff444f;
$color-red-1: #ec3f3f;
$color-red-2: #cc2e3d;
$color-red-3: #a32430;
$color-red-4: #d33636;
$color-red-5: #eb3e48;
$color-red-6: #ec3f3f29;
$color-red-7: #ffe1e3;
$color-red-8: #661b20;
$color-red-9: #b33037;
$color-red-10: #ff444f;
$color-violet: #4a3871;
$color-white: #ffffff;
$color-yellow: #ffad3a;
$color-yellow-1: #b3760d;
$color-yellow-2: #ffa912;
$color-yellow-3: rgba(255, 173, 58, 0.16);

/* status colors */
$color-status-warning: rgba(255, 173, 58, 0.16);
$color-status-information: rgba(55, 124, 252, 0.16);
$color-status-announcement: rgba(75, 180, 179, 0.16);
$color-status-error: rgba(236, 63, 63, 0.16);

/* alpha colors */
$alpha-color-black-1: transparentize($color-black-7, 0.28);
$alpha-color-black-2: transparentize($color-black, 0.04);
$alpha-color-black-3: transparentize($color-black-7, 0.92);
$alpha-color-black-4: transparentize($color-black-7, 0.84);
$alpha-color-black-5: transparentize($color-black-7, 0.16);
$alpha-color-black-6: transparentize($color-black-7, 0.36);
$alpha-color-black-7: transparentize($color-black, 0.5);
$alpha-color-blue-1: transparentize($color-blue, 0.84);
$alpha-color-blue-2: transparentize($color-blue-3, 0.84);
$alpha-color-blue-3: transparentize($color-blue, 0.92);
$alpha-color-white-1: transparentize($color-white, 0.04);
$alpha-color-white-2: transparentize($color-white, 0.84);
$alpha-color-white-3: transparentize($color-white, 0.92);
$alpha-color-white-4: transparentize($color-white, 0.3);
$alpha-color-red-1: transparentize($color-red, 0.92);
$alpha-color-red-2: transparentize($color-red, 0.84);
$alpha-color-red-3: transparentize($color-red, 0.76);
$alpha-color-green-1: transparentize($color-green-1, 0.08);
$alpha-color-green-2: transparentize($color-green-3, 0.08);
$alpha-color-yellow-1: transparentize($color-yellow, 0.84);

/* gradient colors */
$gradient-color-green-1: linear-gradient(to top, $color-white, transparentize($color-green-1, 0.84));
$gradient-color-green-2: linear-gradient(to top, $color-black, transparentize($color-green-3, 0.84));
$gradient-color-red-1: linear-gradient(to top, $color-white, transparentize($color-red, 0.84));
$gradient-color-red-2: linear-gradient(to top, $color-black, transparentize($color-red, 0.84));
$contract-gradient-color-red-1: linear-gradient(to top, $color-white 85%, transparentize($color-red, 0.84));
$contract-gradient-color-red-2: linear-gradient(to top, $color-black 85%, transparentize($color-red, 0.84));
$gradient-color-white: linear-gradient(to right, transparentize($color-white, 1) -5%, $color-white 71%);
$gradient-color-black: linear-gradient(to right, transparentize($color-black, 1) -5%, $color-black 71%);

$gradient-color-purple: linear-gradient(274.73deg, #5a205d 3.82%, #7f3883 88.49%);
$gradient-color-blue: linear-gradient(274.73deg, #1a205e 3.82%, #122d96 88.49%);
$gradient-color-orange: linear-gradient(90deg, #eb001b 0%, #f79e1b 100%);
$gradient-color-black-2: linear-gradient(274.73deg, #464750 3.82%, #0e0f11 88.49%);
$gradient-color-orange-2: linear-gradient(90deg, #f89e32 0%, #f7931b 103.12%);
$gradient-color-blue-2: linear-gradient(90deg, #3d83cf 0%, #2775ca 100%);
$gradient-color-green-3: linear-gradient(90deg, #98cc53 0%, #8dc640 100%);
$gradient-color-blue-3: linear-gradient(90deg, #1a77ac 0%, #0068a3 100%);
$gradient-color-orange-3: linear-gradient(90deg, #ff7635 0%, #ff671f 100%);
$gradient-color-orange-4: linear-gradient(90deg, #f36938 0%, #f25822 100%);
$gradient-color-blue-4: linear-gradient(90deg, #1a8fff 0%, #0083ff 100%);
$gradient-color-red-3: linear-gradient(90deg, #ff444f 0%, #211d1e 95.22%);
$gradient-color-red-4: linear-gradient(90deg, #ff6444 0%, #ff444f 100%);
$gradient-color-black-3: linear-gradient(58.51deg, #061828 28.06%, #1a3c60 93.51%);
$gradient-color-black-4: linear-gradient(274.25deg, #333333 9.01%, #5c5b5b 103.31%);
$gradient-color-blue-5: linear-gradient(90deg, #00a8af 0%, #04cfd8 104.41%);
$gradient-color-gold: linear-gradient(90deg, #f7931a 0%, #ffc71b 104.41%);
$gradient-color-green-4: linear-gradient(90deg, #1db193 0%, #09da7a 104.41%);

/* Preserve legacy variables */
/* Primary */

$COLOR_BLACK: #000000;
$COLOR_BLACK_2: #1d1f20;
$COLOR_BLACK_3: #0e0e0e;
$COLOR_GREEN_1: #39b19d;
$COLOR_GREEN_2: #2d9f93;
$COLOR_GREEN_3: #21ce99;
$COLOR_GREEN_4: #00a79e;
$COLOR_GREEN_5: #4bb4b3;
$COLOR_ORANGE: #ff9933;
$COLOR_DARK_ORANGE: #ff8802;
$COLOR_PURPLE: #4f60ae;
$COLOR_RED: #e31c4b;
$COLOR_RED_2: #cc2e3d;
$COLOR_RED_3: #ec3f3f;
$COLOR_CORAL_RED: #ff444f;
$COLOR_SKY_BLUE: #2196f3;
$COLOR_WHITE: #ffffff;
$COLOR_BLUE: #1c5ae3;
// Light theme
$COLOR_LIGHT_BLACK_1: rgba(0, 0, 0, 0.8);
$COLOR_LIGHT_BLACK_2: rgba(0, 0, 0, 0.4);
$COLOR_LIGHT_BLACK_3: rgba(0, 0, 0, 0.16);
$COLOR_LIGHT_BLACK_3_SOLID_1: #d6d6d6;
$COLOR_LIGHT_BLACK_3_SOLID_2: #b3b3b3;
$COLOR_LIGHT_BLACK_4: rgba(0, 0, 0, 0.04);
$COLOR_LIGHT_BLACK_4_SOLID: #f4f4f6;
$COLOR_LIGHT_GRAY_1: #999cac;
$COLOR_LIGHT_GRAY_2: rgba(153, 156, 172, 0.32);
$COLOR_LIGHT_GRAY_3: #eaeced;
$COLOR_LIGHT_GRAY_4: #6e6e6e;
$COLOR_LIGHT_GRAY_5: #c2c2c2;
$COLOR_LIGHT_GRAY_6: #f2f3f4;
$COLOR_LIGHT_GREEN_GRADIENT: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 148, 117, 0.16));
$COLOR_LIGHT_RED_GRADIENT: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(227, 28, 75, 0.16));
$COLOR_LIGHT_WHITE_GRADIENT: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
// Dark theme
$COLOR_DARK_BLACK_GRADIENT: linear-gradient(to right, rgba(16, 19, 32, 1), rgba(16, 19, 32, 0));
$COLOR_DARK_BLUE_1: #0b0e18;
$COLOR_DARK_BLUE_2: #101320;
$COLOR_DARK_BLUE_3: #191c31;
$COLOR_DARK_BLUE_4: #202641;
$COLOR_DARK_BLUE_5: #2a3052;
$COLOR_DARK_BLUE_6: #555975;
$COLOR_DARK_BLUE_7: #7f8397;
$COLOR_DARK_BLUE_8: rgba(127, 131, 151, 0.3);
$COLOR_DARK_GREEN_GRADIENT: linear-gradient(to top, rgba(16, 19, 32, 0), rgba(0, 148, 117, 0.16));
$COLOR_DARK_RED_GRADIENT: linear-gradient(to top, rgba(16, 19, 32, 0), rgba(227, 28, 75, 0.16));
$COLOR_DARK_GRAY_1: #282a37;
$COLOR_DARK_GRAY_2: #303342;
$COLOR_DARK_GRAY_3: #555975;
$COLOR_DARK_GRAY_4: #999999;
/* stylelint-enable color-no-hex */

$BORDER_RADIUS: 4px;

$MAX_CONTAINER_WIDTH: 1440px;

$POSITIONS_DRAWER_WIDTH: 240px;
$POSITIONS_DRAWER_MARGIN: 8px;

$HEADER_HEIGHT: 48px;
$FOOTER_HEIGHT: 36px;

$MOBILE_HEADER_HEIGHT: 40px;
$MOBILE_HEADER_HEIGHT_DASHBOARD: 48px;
$MOBILE_WRAPPER_HEADER_HEIGHT: 40px;
$MOBILE_WRAPPER_FOOTER_HEIGHT: 70px;

$SIDEBAR_WIDTH: 240px;

/* Wallet gradient background */
$gradient-airtm: linear-gradient(90deg, #1a8fff 0%, #0083ff 100%);
$gradient-banxa: linear-gradient(90deg, #000000 0%, #4ac0ba 96.35%);
$gradient-bitcoin: linear-gradient(90deg, #f89e32 0%, #f7931b 103.12%);
$gradient-credit: linear-gradient(274.73deg, #464750 3.82%, #0e0f11 88.49%);
$gradient-dp2p: linear-gradient(90deg, #ff444f 0%, #211d1e 95.22%);
$gradient-fasapay: linear-gradient(90deg, #f6931c 0%, #4873ac 95.22%);
$gradient-jeton: linear-gradient(90deg, #ff7635 0%, #ff671f 100%);
$gradient-mastercard: linear-gradient(90deg, #eb001b 0%, #f79e1b 100%);
$gradient-neteller: linear-gradient(90deg, #98cc53 0%, #8dc640 100%);
$gradient-paylivre: linear-gradient(90deg, #352caa 0%, #9a6bfc 100%);
$gradient-paysafe: linear-gradient(90deg, #e3001b 0%, #008ac9 100%);
$gradient-perfectmoney: linear-gradient(90deg, #f12c2c 0%, #ef1515 100%);
$gradient-skrill: linear-gradient(274.73deg, #5a205d 3.82%, #7f3883 88.49%);
$gradient-sticpay: linear-gradient(90deg, #f36938 0%, #f25822 100%);
$gradient-virtual: linear-gradient(90deg, #ff6444 0%, #ff444f 100%);
$gradient-visa: linear-gradient(274.73deg, #1a205e 3.82%, #122d96 88.49%);
$gradient-webmoney: linear-gradient(90deg, #1a77ac 0%, #0068a3 100%);

/* App Cards gradient background */
$gradient-virtual: linear-gradient(274.25deg, #333333 9.01%, #5c5b5b 103.31%);
$gradient-virtual-swap-free: linear-gradient(58.51deg, #061828 28.06%, #1a3c60 93.51%);

/*------------------------------------*
 *  # SASS Mixins and Functions
 *------------------------------------*/

/*
 * SASS interpolation
 */
// Requires the calc-interpolation function which can also be used independently
@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
    $a: ($max-value - $min-value) / ($max-screen - $min-screen);
    $b: $min-value - $a * $min-screen;

    $sign: '+';
    @if ($b < 0) {
        $sign: '-';
        $b: abs($b);
    }
    @return calc(#{$a * 100}vw #{$sign} #{$b});
}

@mixin interpolate($properties, $min-screen, $max-screen, $min-value, $max-value) {
    & {
        @each $property in $properties {
            #{$property}: $min-value;
        }
        @media screen and (min-width: $min-screen) {
            @each $property in $properties {
                #{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value);
            }
        }
        @media screen and (min-width: $max-screen) {
            @each $property in $properties {
                #{$property}: $max-value;
            }
        }
    }
}

/*
 * PX to EM
 * @param $px - px value to be converted
 * @param $base - base font size (in `em`)
 * Note: 'em' values are calculate based on the element font-size
 *       to properly converts 'px' to 'em', please pass in the element font-size with it
 * Usage example:
    padding: em(16px, 1.6em); // font-size in 'em'
 */
@function em($px, $base: $BASE_FONT_SIZE) {
    $list: ();
    @if length($px) != 1 {
        @for $i from 1 through length($px) {
            $val_em: (nth($px, $i) / $base) * 1em;
            $list: append($list, $val_em, space);
        }
        @return $list;
    } @else {
        @return ($px / $base) * 1em;
    }
}

/*
 * Set property by passing a property name, and values.
 * @param $property name - padding, margin etc.
 * @param $values        - values in `px` (space separated for multiple values)
 * @param $font-size     - base font-size in `em`
 * Usage example:
   @include toEm(padding, 8px 16px 8px, 1.6em);
 */
@mixin toEm($property, $values, $font-size: $BASE_FONT_SIZE) {
    #{$property}: em($values, $font-size);
}

/*
 * Generate typefaces key-value pair of variable name and config
 * Example:
   --paragraph-center-bold-black: (14px, center, bold, black)
 */
@function generate-typefaces() {
    $typeface_list: ();
    @each $fontsize_name, $size in $FONT_SIZES {
        @each $textalign_name, $text_align in $TEXT_ALIGN {
            @each $color_name, $color in $FONT_COLORS {
                @each $fontweight_name, $weight in $FONT_WEIGHTS {
                    $key: --#{$fontsize_name}-#{$textalign_name}-#{$fontweight_name}-#{$color_name};
                    $val: ($size, $text_align, $weight, $color);
                    $typeface: (
                        $key: $val,
                    );
                    $typeface_list: map-merge($typeface_list, $typeface);
                }
            }
        }
    }
    // @debug $typeface_list; /* uncomment to debug */
    @return $typeface_list;
}

/*
 * Sets font-size, font-weight, color, text-transform, text-align and line-height
 * Usage example:
   @include setTypeface(16px, bold, black, uppercase);
 */
@mixin setTypeface($size, $align, $weight, $colour, $tt) {
    @if $size {
        font-size: $size;
    }
    @if $colour {
        color: $colour;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $tt {
        text-transform: $tt;
    }
    @if $align {
        text-align: $align;
    }
    @if $size == 1.6rem {
        line-height: map-get($map: $LINEHEIGHTS, $key: 'large');
    } @else if $size == 1.4rem {
        line-height: map-get($map: $LINEHEIGHTS, $key: 'medium');
    } @else if $size == 1.2rem {
        line-height: map-get($map: $LINEHEIGHTS, $key: 'small');
    } @else if $size == 1rem {
        line-height: map-get($map: $LINEHEIGHTS, $key: 'xsmall');
    } @else {
        line-height: unset;
    }
}

/*
 * Set typefaces by passing a typeface variable name
 * @param $var   - typeface variable
 * @param $tt    - text-transform property
 * @param $align - text-align property
 * Usage example:
   @include typeface(--paragraph-bold-black, uppercase, center);
 */
@mixin typeface($var, $tt: none) {
    $typeface_config: map-get(
        $map: $TYPEFACES_LIST,
        $key: $var,
    );

    $size: nth($typeface_config, 1); // font-size
    $align: nth($typeface_config, 2); // text-align
    $weight: nth($typeface_config, 3); // font-weight
    $color: nth($typeface_config, 4); // color
    @include setTypeface($size, $align, $weight, $color, $tt);
}

/*
 * Range Slider Thumb styling is set here
 */
@mixin thumbStyle() {
    appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 100%;
    border: 0;
    cursor: pointer;
    transition: box-shadow 0.2s;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);

    &:hover {
        box-shadow: 0 0 0 0.5em var(--state-hover);
    }
}

/*
 * SVG icons colors
 */
@mixin colorIcon($colors...) {
    @for $i from 1 through length($colors) {
        /* postcss-bem-linter: ignore */
        .color#{$i}-fill {
            fill: nth($colors, $i);
        }
        /* postcss-bem-linter: ignore */
        .color#{$i}-stroke {
            stroke: nth($colors, $i);
        }
    }
}

/*
 * Bar Spinner Animation
 * @param $count    - number of bars
 * @param $duration - duration of animation
 * @param $phase    - each bar phase change delay
 * Usage example:
   @include createBarspinnerAnimation(5, 1.2s, 0,1);
 */
@mixin createBarspinnerAnimation($count, $duration, $phase) {
    animation: sk-stretchdelay $duration infinite ease-in-out;

    @for $i from 1 through $count {
        &--#{$i} {
            animation-delay: -$duration + (($i - 1) * $phase);
        }
    }
}

/*
 * Tooltip colors
 * @param $color - color property
 * Usage example:
   @include tooltipColor($COLOR_RED);
 */
@mixin tooltipColor($color) {
    &:before {
        background: $color;
    }
    &:after,
    &[data-tooltip-pos='top']:after {
        border-top-color: $color;
    }
    &[data-tooltip-pos='right']:after {
        border-top-color: transparent;
        border-right-color: $color;
    }
    &[data-tooltip-pos='left']:after {
        border-top-color: transparent;
        border-left-color: $color;
    }
    &[data-tooltip-pos='bottom']:after {
        border-top-color: transparent;
        border-bottom-color: $color;
    }
}

/*
 * Convert Tooltip colors
 * @param $color - css color property
 * Usage example:
   @include convertTooltipColor(var(--status-default));
 */
@mixin convertTooltipColor($color) {
    &:before {
        background: $color;
    }
    &:after,
    &[data-tooltip-pos='top']:after {
        border-top-color: $color;
    }
    &[data-tooltip-pos='right']:after {
        border-top-color: transparent;
        border-right-color: $color;
    }
    &[data-tooltip-pos='left']:after {
        border-top-color: transparent;
        border-left-color: $color;
    }
    &[data-tooltip-pos='bottom']:after {
        border-top-color: transparent;
        border-bottom-color: $color;
    }
}

/*
    Constants
*/
$FONT_STACK: 'IBM Plex Sans', sans-serif;
$BASE_FONT_SIZE: 10px;

/*
    Variables
*/
$FONT_SIZES: (
    'xheading' 3.2rem,
    'heading' 3rem,
    'large' 2.8rem,
    'title' 1.6rem,
    'paragraph' 1.4rem,
    'small' 1.2rem,
    'xsmall' 1rem,
    'xxsmall' 0.8rem,
    'xxxsmall' 0.6rem
);

$FONT_COLORS: (
    'active' var(--text-colored-background),
    'prominent' var(--text-prominent),
    'black' var(--text-general),
    'grey' var(--text-less-prominent),
    'disabled' var(--text-disabled),
    'green' var(--text-profit-success),
    'red' var(--text-loss-danger)
);

$FONT_WEIGHTS: ('bold' 700, 'semibold' 500, 'normal' 400, 'light' 300);

$TEXT_ALIGN: ('center' center, 'left' left, 'right' right);

$LINEHEIGHTS: (
    'large': 1.75,
    'medium': 1.5,
    'small': 1.25,
    'xsmall': 1.4,
);

/*
    List of all typefaces variables in the format: --$FONT_SIZES-$TEXT_ALIGN-$FONT_WEIGHTS-COLORS.
    See $FONT_SIZES, $TEXT_ALIGN, $FONT_WEIGHTS, $COLORS maps above for references.
    Variables name example: --title-center-semibold-red
 */
$TYPEFACES_LIST: generate-typefaces();

/*
    to use styles without modifier class on .inline-icon
    use @extend

    e.g. style icon on parent hover:
        a:hover .inline-icon {
            @extend %inline-icon.active;
        }
*/

%inline-icon {
    @include colorIcon(var(--text-general), none);

    &.active {
        @include colorIcon(var(--text-prominent), none);
    }
    &.disabled {
        @include colorIcon(var(--text-disabled), none);
    }
    &.white {
        @include colorIcon(var(--text-prominent));
    }
    &.border_hover_color {
        @include colorIcon(var(--text-prominent));
    }
    &.secondary {
        @include colorIcon(var(--text-less-prominent));
    }
}

/**
 * Define Breakpoints here.
 */
$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin tablet-down {
    @media (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin tablet-up {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin mobile-up {
    @media (min-width: #{$mobile-width}) {
        @content;
    }
}

@mixin mobile {
    @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1}) {
        @content;
    }
}

@mixin mobile-landscape {
    @media only screen and (min-width: #{$mobile-width}) and (max-width: #{$desktop-width - 1}) and (orientation: landscape) {
        @content;
    }
}

@mixin touch-device {
    // add css interaction media query to detect touch devices
    // refer to: https://caniuse.com/#feat=css-media-interaction
    @media (pointer: coarse) {
        @content;
    }
}

@mixin mobile-tablet-mix {
    @media (min-width: #{$mobile-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

.modal__container.modal__real-account-signup-modal {
    .dc-vertical-tab {
        &__content {
            padding: 0 !important;
        }
    }
}

.accept-risk {
    &__header {
        padding: 2.4rem 0;
    }
    &__container {
        padding: 0 2.4rem;

        & .dc-text {
            text-align: justify;
        }
        @include mobile {
            padding: 0 1.6rem;
        }
    }
    &__form {
        display: grid;
        height: calc(100% - 57px);
        position: relative;
        width: 100%;
    }
}

.account-signup-loader {
    width: 100%;
    height: 100%;
}

// Min width of tablet to max size of the modal
@media (min-width: 768px) and (max-width: 904px) {
    .dc-modal__container {
        &_real-account-signup-modal {
            width: 768px !important;
        }
        &--risk-message {
            width: 440px !important;
        }
    }
}
.poi-form-on-signup {
    height: 100%;
    overflow: auto;
    width: 100%;
    &__fields {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        @include mobile {
            display: unset;
            position: relative;
        }

        .proof-of-identity {
            &:is(span) {
                width: unset;
                height: unset;
            }
            &__container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 45%;
                justify-content: unset;

                @include mobile {
                    width: 100%;
                    overflow-y: unset;
                    justify-content: unset;
                }

                .icon {
                    width: 12.8rem;
                    height: 12.8rem;
                    margin: 1.6rem 0 2.4rem;
                }
                .dc-input__bottom-label {
                    margin: unset;
                }
                .btm-spacer {
                    margin-bottom: 1.6rem;
                }
                .spacer {
                    margin: 1.6rem 0;
                }

                &--idv {
                    width: 100%;
                }
            }

            &__inner-container {
                display: unset;
                flex-direction: unset;
                justify-content: unset;
                align-items: unset;
                width: 100%;
            }
            &__image {
                width: 100%;
                max-width: unset;
                border-radius: unset;
                object-fit: unset;

                &-container {
                    width: unset;
                    height: unset;
                    padding: unset;
                    border-radius: unset;
                    background-color: unset;
                }
            }
            &__fieldset {
                width: 100%;
                margin-bottom: 2rem;

                @include mobile {
                    margin: 0 0 1.8rem;
                }

                &-container {
                    display: unset;
                    flex-direction: unset;
                    justify-content: unset;
                    align-items: unset;
                }
                &-input {
                    width: unset;
                    margin-bottom: 1rem;
                }
            }
            &__sample-container {
                margin-top: 2.4rem;
                margin-left: unset;
                width: unset;
            }

            &__submit-button {
                margin-left: 0.8rem;
                @include mobile {
                    margin-right: unset;
                }
            }
        }
    }
    & .details-form {
        display: grid;
        grid-template-rows: minmax(10rem, 1fr) 8.2rem;
        height: 100%;
        position: relative;

        @include mobile {
            max-height: calc(100% - 5rem);
        }
    }
    @include mobile {
        overflow: hidden;
    }
}

.idv-form {
    display: grid;
    grid-template-rows: unset !important;

    .details-form {
        @include mobile {
            overflow: unset;
        }

        @include desktop {
            padding: unset;
        }
    }
}

.mt5-layout {
    @include desktop {
        display: flex;
        flex-direction: column;
    }
    &__container {
        overflow: auto;
        height: 100%;
        @include desktop {
            padding: 0.8rem 15.5rem;
        }
        @include mobile {
            padding: 1.6rem;
        }
    }

    .proof-of-identity {
        &__footer {
            padding: 1.6rem 2.4rem;
            justify-content: end;
            display: flex;
            border-top: 1px solid var(--general-section-1);
            @include mobile {
                width: 100%;
            }
        }

        &__submit-button {
            @include mobile {
                width: 100%;
                margin: unset;
            }
        }
    }
    .account-form__footer {
        @include desktop {
            position: relative;
        }
    }
}

.proof-of-identity__container.mt5-layout {
    justify-content: space-between;
    height: 100%;
    @include mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}
