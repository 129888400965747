/* @define reset-password */
.reset-password {
    margin: 1.6rem 1.6rem 0;

    &__residence-selection,
    &__password-selection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: nowrap column;
    }
    &__password-selection {
        .reset-password__heading {
            margin-bottom: 3.3rem;
        }
    }
    &__heading {
        margin-bottom: 0.8rem;
    }
    &__text {
        text-align: center;
        margin-bottom: 3.3rem;
    }
    &__subtext {
        margin: 1rem 0 2.4rem;
    }
    &__btn {
        margin: 0;
        background-color: var(--button-primary-default);
        font-weight: bold;
        color: var(--text-colored-background);
        font-size: 1.2rem;

        &:hover {
            background-color: var(--button-primary-hover);
        }
        &--disabled {
            opacity: 0.32;
            cursor: not-allowed !important;
        }
    }
    &__fieldset {
        position: relative;
        width: 100%;
        @include desktop {
            max-width: 30rem;
        }
    }
    &__password-field {
        /* postcss-bem-linter: ignore */
        & > label {
            top: 0.9rem;
            background-color: var(--general-main-2);
        }
    }
}

/* @define reset-trading-password */
.reset-trading-password {
    &__description {
        &--is-centered {
            margin: 0 auto;
            max-width: 70%;
        }
    }
    &__confirm-button {
        margin-top: 2.4rem;
    }
    &__dialog {
        .dc-dialog__dialog {
            max-height: fit-content;
            .dc-dialog__content {
                margin-bottom: 0;
            }
        }
    }
    &__error {
        max-width: 38.4rem;
        min-height: 28.4rem;
        padding-top: 2rem;
        text-align: center;
    }
    &__lead {
        margin: 1.6rem 0 0;
    }
    &__set-password {
        display: flex;
        justify-content: center;
        flex-flow: nowrap column;

        .reset-trading-password__heading {
            margin-bottom: 2.4rem;
        }
    }
    &__password-success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .reset-trading-password {
            &__icon {
                margin: 0 0 2.4rem 3rem;
            }
            &__heading {
                margin-bottom: 0.8rem;
            }
            &__subtext {
                margin-bottom: 2.4rem;
            }
        }
    }

    &__residence-selection,
    &__password-selection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: nowrap column;
    }
    &__password-selection {
        .reset-trading-password__heading {
            margin-bottom: 3.3rem;
        }
    }
    &__heading {
        margin-bottom: 0.8rem;
    }
    &__text {
        text-align: center;
        margin-bottom: 3.3rem;
    }
    &__subtext {
        margin-bottom: 1.6rem;
    }
    &__hint {
        margin-bottom: 2.4rem;
    }
    &__btn {
        width: fit-content;
        align-self: flex-end;
    }
    &__fieldset {
        position: relative;
        width: 100%;
        @include desktop {
            max-width: 30rem;
        }
    }
    &__input-field {
        margin-bottom: 1.2rem;
    }
    &__password-field {
        /* postcss-bem-linter: ignore */
        & > label {
            top: 0.9rem;
            background-color: var(--general-main-2);
        }
    }
}

/* @define unlink-password */
.unlink-password {
    &__dialog {
        .dc-dialog__dialog {
            max-height: fit-content;
            .dc-dialog__content {
                margin-bottom: 0;
            }
        }
    }
    &__set-password {
        display: flex;
        justify-content: center;
        flex-flow: nowrap column;

        .unlink-password__heading {
            margin-bottom: 2.4rem;
        }
    }
    &__password-success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .unlink-password {
            &__icon {
                margin-bottom: 2.4rem;
                margin-left: 1.6rem;
            }
            &__heading {
                margin-bottom: 0.8rem;
            }
            &__subtext {
                margin-bottom: 2.4rem;
            }
        }
    }

    &__residence-selection,
    &__password-selection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: nowrap column;
    }
    &__password-selection {
        .unlink-password__heading {
            margin-bottom: 3.3rem;
        }
    }
    &__heading {
        margin-bottom: 0.8rem;
    }
    &__text {
        text-align: center;
        margin-bottom: 3.3rem;
    }
    &__subtext {
        margin-bottom: 1.6rem;
    }
    &__hint {
        margin-bottom: 2.4rem;
    }
    &__btn {
        width: fit-content;
        align-self: flex-end;
    }
    &__fieldset {
        position: relative;
        width: 100%;
        @include desktop {
            max-width: 30rem;
        }
    }
    &__input-field {
        margin-bottom: 1.2rem;
    }
    &__password-field {
        /* postcss-bem-linter: ignore */
        & > label {
            top: 0.9rem;
            background-color: var(--general-main-2);
        }
    }
}
