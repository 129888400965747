/* postcss-bem-linter: ignore */
html {
    font-size: 62.5%; // Set root font size to 10px (1rem) to convert units in base 10

    /** TODO: define breakpoints */
    // @include breakpoints(big-desktop) {
    //     font-size: 75.5%; /* 1rem = 12px */
    // }
    // @include breakpoints(tab-land) {
    //     font-size: 50%; /* 1rem = 8px */
    // }
    // @include breakpoints(tab-port) {
    //     font-size: 47%; /* 1rem = 7.5px */
    // }
    // @include breakpoints(phone-xl) {
    //     font-size: 38%; /* 1rem = 6px */
    // }
    // @include breakpoints(phone-lg) {
    //     font-size: 35%; /* 1rem = 5.5px */
    // }
}

/** @define body */
.body {
    position: relative;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

/* postcss-bem-linter: ignore */
*,
html,
.body {
    font-family: $FONT_STACK;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    box-sizing: border-box;

    @include mobile {
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}

// Forms
/** @define select */
.select {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    padding: 2px 3px;
    border: 1px solid var(--border-normal);
    border-radius: $BORDER_RADIUS;
}

/** @define input */
.input {
    font-family: inherit;
    font-style: inherit;
    padding: 2px 3px;
    color: var(--text-general);
}

// Placeholders
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--text-general);
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--text-general);
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--text-general);
}

:-moz-placeholder {
    /* Firefox 18- */
    color: var(--text-general);
}

/** @define theme */
.theme {
    &--light {
        background: $color-white;
    }
    &--dark {
        background: $color-black;
    }
}

/** @define container */
.container {
    width: 100%;
    max-width: $MAX_CONTAINER_WIDTH;
    margin: 0 auto;
    box-sizing: border-box;
}

/** @define hidden */
.hidden {
    visibility: hidden !important;
    transition: none !important;
}

// Centers absolute element vertically
/** @define absolute-center-v */
.absolute-center-v {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
}

/** @define link */
.link {
    color: var(--brand-red-coral);
    font-weight: bold;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    &--white {
        color: $color-white;
    }
    &--right {
        float: right;
    }
    &--prominent {
        color: var(--text-prominent);
    }
}

.link--no-bold {
    color: var(--text-red);
    text-decoration: none;
}

.link.dark {
    color: var(--brand-red-grey);
}

/** @define icon */
.icon--centered {
    top: 0.8em;
    left: 5%;
}

/** @define center-text */
.center-text {
    text-align: center;
}

/** @define no-margin */
.no-margin {
    margin: 0;
}

/** @define hr */
.hr {
    border-top: var(--general-section-1);
    display: block;
    height: 1px;
    border: 0;
    margin: 0.5em 0;
    padding: 0;
}

/** @define deriv-app */
.deriv-app {
    height: 100%;
    overflow: hidden;
}
